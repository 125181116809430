<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\documents\\edit.7948') }}</v-toolbar-title>
            </v-toolbar>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-select
                    v-model="entity.trip"
                    :label="$t('src\\views\\documents\\edit.4927')"
                    :items="tripsSelect"
                    item-text="name"
                    item-value="_id"
                    outlined
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-select
                    v-model="entity.user"
                    :label="$t('src\\views\\documents\\edit.3824')"
                    :items="usersSelect"
                    item-text="name"
                    item-value="_id"
                    outlined
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <div
                    class="pa-6 mx-0 mb-4"
                    style="border: 1px solid #00000061; border-radius: 4px;">
                    <p>{{ $t('src\\views\\documents\\edit.2375') }}</p><br />
                    <v-row class="mx-0">
                        <template v-for="(document, index) in entity.documents">
                            <v-col
                                :key="`document-${index}`"
                                cols="12"
                                class="pa-0">
                                <div
                                    class="pa-1 mx-0 my-2"
                                    style="border: 1px solid #00000061; border-radius: 4px;">
                                    <v-row class="ma-0 d-flex justify-center align-center">
                                        <v-col cols="5">
                                            <v-text-field
                                                v-model="document.title"
                                                :label="$t('src\\views\\documents\\edit.8032')"
                                                hide-details
                                                outlined
                                                class="ma-1"
                                            />
                                        </v-col>
                                        <v-col cols="5">
                                            <span v-if="document.file && document.file !== ''">
                                                <v-icon color="primary">mdi-file</v-icon> {{fileName(document.file)}}
                                            </span>
                                            <v-file-input
                                                v-else
                                                :label="$t('src\\views\\documents\\edit.8956')"
                                                outlined
                                                prepend-icon="mdi-file"
                                                hide-details
                                                @change="setDocument($event, index)"
                                            />
                                        </v-col>
                                        <v-col cols="2">
                                            <v-btn
                                                color="error"
                                                @click="removeDocument(index)"
                                                class="ma-1"
                                            >{{ $t('src\\views\\documents\\edit.3449') }}</v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </template>
                    </v-row>
                    <v-row
                        align="center"
                        justify="space-between"
                        class="mx-0 my-2">
                        <v-btn
                            color="primary"
                            @click="addDocument">{{ $t('src\\views\\documents\\edit.3672') }}</v-btn>
                    </v-row>
                </div>
            </ValidationProvider>
            <v-textarea
                v-model="entity.comment"
                :label="$t('src\\views\\documents\\edit.2989')"
                outlined
            />
            <v-row
                align="center"
                justify="space-between"
                class="mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\documents\\edit.3520') }}</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\documents\\edit.2152') }}</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { fileName } from '../../helpers';

    export default {
        name: 'DocumentsEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            fileName,
            valid: true
        }),
        computed: {
            ...mapState('documents', ['entity']),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            ...mapState('users', {
                users: state => state.entities
            }),
            tripsSelect() {
                if(this.trips) {
                    return [
                        { _id: null, name: 'По умолчанию' },
                        ...this.trips.map(item => {
                            return {
                                _id: item._id,
                                name: item.name
                            }
                        }).sort((a, b) => {
                            return a.name > b.name ? 1 : -1;
                        })
                    ]
                }
                return [];
            },
            usersSelect() {
                if(this.users) {
                    return [
                        { _id: null, name: 'По умолчанию' },
                        ...this.users.filter(item => item.firstname).map(item => {
                            return {
                                _id: item._id,
                                name: `${item.firstname} ${item.lastname}`
                            }
                        }).sort((a, b) => {
                            return a.name > b.name ? 1 : -1;
                        })
                    ]
                }
                return [];
            },
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('documents/get', { id: this.$route.params.id });
            } else {
                await store.commit('documents/CLEAR_ENTITY');
            }
            await store.dispatch('trips/fetch', { itemsPerPage: 100000 });
            await store.dispatch('users/fetch', { itemsPerPage: 100000 });
        },
        methods: {
            async addDocument() {
                this.entity.documents.push({
                    title: '',
                    file: ''
                });
            },
            async setDocument(file, index) {
                const document = await store.dispatch('files/upload', { file });
                this.entity.documents[index].file = document;
            },
            async removeDocument(index) {
                this.entity.documents.splice(index, 1);
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('documents/SET_ENTITY', this.entity);
                    await store.dispatch('documents/save');
                    await this.$router.push({ name: 'documents-list' });
                }
            },
            async remove() {
                await store.dispatch('documents/delete', { id: this.entity._id });
                await this.$router.push({ name: 'documents-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
</style>
